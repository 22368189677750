// extracted by mini-css-extract-plugin
export var lbContainerOuter = "H_wG";
export var lbContainerInner = "H_wH";
export var movingForwards = "H_wJ";
export var movingForwardsOther = "H_wK";
export var movingBackwards = "H_wL";
export var movingBackwardsOther = "H_wM";
export var lbImage = "H_wN";
export var lbOtherImage = "H_wP";
export var prevButton = "H_wQ";
export var nextButton = "H_wR";
export var closing = "H_wS";
export var appear = "H_wT";