// extracted by mini-css-extract-plugin
export var alignDiscLeft = "w_sk d_fn d_bF d_dt";
export var alignLeft = "w_qq d_fn d_bF d_dt";
export var alignDiscCenter = "w_sl d_fp d_bC d_dv";
export var alignCenter = "w_bN d_fp d_bC d_dv";
export var alignDiscRight = "w_sm d_fq d_bG d_dw";
export var alignRight = "w_qr d_fq d_bG d_dw";
export var footerContainer = "w_sn d_dV d_bV";
export var footerContainerFull = "w_sp d_dS d_bV";
export var footerHeader = "w_kd d_kd";
export var footerTextWrapper = "w_sq d_v";
export var footerDisclaimerWrapper = "w_kl d_kl d_cj";
export var badgeWrapper = "w_sr d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "w_ss undefined";
export var footerDisclaimerLeft = "w_st undefined";
export var verticalTop = "w_sv d_by d_bH d_bM d_bK";
export var firstWide = "w_sw";
export var disclaimer = "w_sx d_by d_bH";
export var socialDisclaimer = "w_sy";
export var left = "w_sz";
export var wide = "w_sB d_cx";
export var right = "w_sC d_bJ";
export var line = "w_fj d_fk d_ct";
export var badgeDisclaimer = "w_sD d_bB d_bN d_bH";
export var badgeContainer = "w_sF d_by d_bG d_bN";
export var badge = "w_sG";
export var padding = "w_sH d_c6";
export var end = "w_sJ d_bG";
export var linkWrapper = "w_sK d_dz";
export var link = "w_mB d_dz";
export var colWrapper = "w_sL d_cw";
export var consent = "w_f d_f d_bB d_bN";
export var media = "w_sM d_bw d_dx";
export var itemRight = "w_sN";
export var itemLeft = "w_sP";
export var itemCenter = "w_sQ";
export var exceptionWeight = "w_sR D_tT";