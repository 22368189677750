// extracted by mini-css-extract-plugin
export var customText = "r_rq d_dt d_cr d_cf";
export var videoIframeStyle = "r_pP d_d4 d_v d_G d_bx d_b0 d_Q";
export var customImageWrapper = "r_rr d_cr d_cf d_Y";
export var customRow = "r_p7 d_bC d_bd";
export var quoteWrapper = "r_gP d_bB d_bN d_cr d_cf";
export var quoteBar = "r_pT d_G";
export var masonryImageWrapper = "r_p0";
export var title = "r_rs";
export var Title3Small = "r_rt D_rt D_tr D_tz";
export var Title3Normal = "r_rv D_rv D_tr D_tB";
export var Title3Large = "r_rw D_rw D_tr D_tC";