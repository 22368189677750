// extracted by mini-css-extract-plugin
export var alignLeft = "v_qq d_fn d_bF d_dt";
export var alignCenter = "v_bN d_fp d_bC d_dv";
export var alignRight = "v_qr d_fq d_bG d_dw";
export var FAQMainHeader = "v_kt d_kt d_v d_bx";
export var FAQComponentHeader = "v_kz d_kz d_bw";
export var FAQComponentParagraph = "v_kv d_kv d_cC";
export var FAQComponentsWrapper = "v_kr d_kr d_cm d_cK d_cQ d_c1 d_cW d_dz";
export var FAQComponentsWrapperClean = "v_ks d_ks d_cm d_cK d_cQ d_cZ d_dz";
export var accordion__body = "v_kB d_kB d_bx";
export var accordion__bodyHidden = "v_kD d_kD d_bv d_dk";
export var FAQDivider = "v_kq d_kq";
export var accordionWrapper = "v_sd d_cf";
export var accordion__item = "v_sf d_cK";
export var accordion_arrow = "v_kH d_kH";
export var accordion_arrow_open = "v_kK d_kK";
export var accordion_arrow_2 = "v_kM d_kM";
export var accordion_arrow_2_open = "v_kP d_kP";
export var Subtitle2Small = "v_sg D_sg D_tr D_tH";
export var Subtitle2Normal = "v_sh D_sh D_tr D_tJ";
export var Subtitle2Large = "v_sj D_sj D_tr D_tK";