// extracted by mini-css-extract-plugin
export var carouselContainer = "G_qg d_v d_G d_bd d_Y";
export var carouselContainerCards = "G_vT G_qg d_v d_G d_bd d_Y";
export var carouselContainerSides = "G_vV d_v d_G d_Y";
export var prevCarouselItem = "G_vW d_v d_G d_Z d_j";
export var prevCarouselItemL = "G_vX G_vW d_v d_G d_Z d_j";
export var moveInFromLeft = "G_vY";
export var prevCarouselItemR = "G_vZ G_vW d_v d_G d_Z d_j";
export var moveInFromRight = "G_v0";
export var selectedCarouselItem = "G_v1 d_v d_G d_Y d_bd";
export var selectedCarouselItemL = "G_v2 G_v1 d_v d_G d_Y d_bd";
export var selectedCarouselItemR = "G_v3 G_v1 d_v d_G d_Y d_bd";
export var nextCarouselItem = "G_v4 d_v d_G d_Z d_j";
export var nextCarouselItemL = "G_v5 G_v4 d_v d_G d_Z d_j";
export var nextCarouselItemR = "G_v6 G_v4 d_v d_G d_Z d_j";
export var arrowContainer = "G_v7 d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainer = "G_v8 G_v7 d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainerHidden = "G_v9 G_v8 G_v7 d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var nextArrowContainer = "G_wb G_v7 d_G d_Z d_dz d_bk d_3 d_b0";
export var carouselContainerProducts = "G_wc";
export var nextArrowContainerHidden = "G_wd G_wb G_v7 d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var arrow = "G_kG d_Z";
export var prevArrow = "G_wf G_kG d_Z";
export var nextArrow = "G_wg G_kG d_Z";
export var carouselIndicatorContainer = "G_wh d_v d_dv d_bk d_by d_bC d_bN d_bH d_Z";
export var btnsContainer = "G_wj d_v d_by d_bD";
export var carouselText = "G_wk d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var carouselTextInactive = "G_wl G_wk d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var indicator = "G_wm d_b6";
export var carouselIndicator = "G_wn G_wm d_b6";
export var carouselIndicatorSelected = "G_wp G_wm d_b6";
export var arrowsContainerTopRight = "G_wq d_Z d_bk d_bB";
export var arrowsContainerBottomLeft = "G_wr d_Z d_bk d_bB";
export var arrowsContainerSides = "G_ws d_Z d_bk d_bB";
export var smallArrowsBase = "G_wt d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainer = "G_wv G_wt d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrev = "G_ww G_wv G_wt d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerSmall = "G_wx G_wt d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrevSmall = "G_wy G_wx G_wt d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var icon = "G_p6";
export var iconSmall = "G_wz";
export var multipleWrapper = "G_wB d_bB d_bD d_bd";
export var multipleImage = "G_wC d_bB";
export var sidesPrevContainer = "G_wD G_wv G_wt d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";
export var sidesNextContainer = "G_wF G_wv G_wt d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";