// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "x_sS d_gt d_cr";
export var heroHeaderCenter = "x_gv d_gv d_cr d_dv";
export var heroHeaderRight = "x_gw d_gw d_cr d_dw";
export var heroParagraphLeft = "x_sT d_gq d_cv";
export var heroParagraphCenter = "x_gr d_gr d_cv d_dv";
export var heroParagraphRight = "x_gs d_gs d_cv d_dw";
export var heroBtnWrapperLeft = "x_sV d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "x_sW d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "x_sX d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "x_sY d_gp d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "x_sZ d_gn d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "x_s0 D_s0";
export var heroExceptionNormal = "x_s1 D_s1";
export var heroExceptionLarge = "x_s2 D_s2";
export var Title1Small = "x_s3 D_s3 D_tr D_ts";
export var Title1Normal = "x_s4 D_s4 D_tr D_tt";
export var Title1Large = "x_s5 D_s5 D_tr D_tv";
export var BodySmall = "x_r9 D_r9 D_tr D_tL";
export var BodyNormal = "x_sb D_sb D_tr D_tM";
export var BodyLarge = "x_sc D_sc D_tr D_tN";