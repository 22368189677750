// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "z_td d_bC";
export var storyRowWrapper = "z_hw d_hw d_bJ";
export var storyLeftWrapper = "z_tf d_by d_bN";
export var storyWrapperFull = "z_tg d_cC";
export var storyWrapperFullLeft = "z_nM d_cC d_by d_bN d_bC";
export var contentWrapper = "z_pG d_hx";
export var storyLeftWrapperCenter = "z_th d_hy d_by d_bC d_bN d_bH";
export var storyRightWrapperCenter = "z_tj d_hD";
export var storyHeader = "z_tk d_hC d_v d_cr";
export var storyHeaderCenter = "z_hB d_hB d_v d_cr d_dv d_bx";
export var storyParagraphCenter = "z_hz d_hz d_bx d_dv";
export var storyBtnWrapper = "z_tl d_d1 d_d0 d_v d_by d_bF";
export var storyBtnWrapperCenter = "z_hH d_hH d_d0 d_v d_by d_bC";
export var imageWrapper = "z_rV d_ff d_Y";
export var imageWrapperFull = "z_rW d_v d_G d_bd d_Y";