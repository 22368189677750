// extracted by mini-css-extract-plugin
export var alignLeft = "t_qq d_bF";
export var alignCenter = "t_bN d_bC";
export var alignRight = "t_qr d_bG";
export var embedInnerWrapperDesign1 = "t_r5 d_bL";
export var embed = "t_r6 d_b0";
export var titleMargin = "t_rY d_cv d_dv";
export var subtitleMargin = "t_r7 d_cr d_dv";
export var paragraphMargin = "t_r8 d_cv d_dv";
export var SubtitleSmall = "t_p9 D_p9 D_tr D_tD";
export var SubtitleNormal = "t_qb D_qb D_tr D_tF";
export var SubtitleLarge = "t_qc D_qc D_tr D_tG";
export var BodySmall = "t_r9 D_r9 D_tr D_tL";
export var BodyNormal = "t_sb D_sb D_tr D_tM";
export var BodyLarge = "t_sc D_sc D_tr D_tN";