// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "s_rx d_bC";
export var datasheetRowWrapper = "s_lt d_lt d_bJ";
export var datasheetLeftWrapper = "s_ry d_by d_bN";
export var datasheetWrapperFull = "s_rz d_cC";
export var datasheetWrapperFullLeft = "s_nG d_cC d_by d_bN d_bC d_bS";
export var contentWrapper = "s_pG d_lv";
export var contentWrapperCenter = "s_rB d_lz";
export var datasheetLeftWrapperCenter = "s_rC d_lx d_by d_bC d_bN d_bH";
export var datasheetImageCenterWrapper = "s_lr d_lr d_v d_ct";
export var datasheetRightWrapperCenter = "s_rD d_lw";
export var datasheetFooter = "s_ly d_ly";
export var alignLeft = "s_qq d_dt";
export var alignCenter = "s_bN d_dv";
export var alignRight = "s_qr d_dw";
export var datasheetInnerWrapperNewLeft = "s_rF d_fd d_N d_Y d_by d_bN d_dh";
export var datasheetInnerWrapperLeft = "s_rG d_fc d_N d_Y d_by d_bN d_b4 d_dh d_c8";
export var datasheetInnerWrapperRight = "s_rH d_fd d_N d_Y d_by d_bN d_dh";
export var datasheetRowWrapperReverse = "s_rJ d_bJ";
export var datasheetSubtitle = "s_rK d_cw";
export var tableRow = "s_rL";
export var cell = "s_rM";
export var tableRowWrapper = "s_rN d_v d_dt";
export var tableHeadingCell = "s_rP";
export var tableHeading = "s_rQ";
export var tableRowStriped = "s_rR";
export var tableRowHeading = "s_rS";
export var dataTable = "s_rT d_v";
export var imageWrapper = "s_rV d_ff d_Y";
export var imageWrapperFull = "s_rW d_G d_v d_bd d_Y";
export var imageWrapperIcon = "s_rX";
export var titleMargin = "s_rY d_cr";
export var datasheetInnerInnerWrapper = "s_rZ d_v";
export var hasLabels = "s_r0";
export var label = "s_r1";
export var SmallSmall = "s_r2 D_r2 D_tr D_tP";
export var SmallNormal = "s_r3 D_r3 D_tr D_tQ";
export var SmallLarge = "s_r4 D_r4 D_tr D_tM";